@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';


//@import './fonts';

@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar{
  .htmlPanel{
    p{
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.choose-file-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  label.choose-file-label{
    background-color: white;
    margin-bottom: 0px !important;
    //font-family: $family-button;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 600;
    //color: #444;
  }
  div.file__attach__text{
    margin-left: 1rem;
    //color: white;
  }
}

body:not(.cmsedit) {
  div.cms_item.desktop-ig-feed {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  div.cms_item.mobile-ig-feed {
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
}

.common-footer {

  &>div.container {
    //max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
  }

  .multiColumnPanel {
    max-width: none;

    &>div.container {
      max-width: none;
    }

    .container {
      margin-left: auto;
      margin-right: auto;
      padding-left: 0px;
      padding-right: 0px;
    }

    div.row>div {
      padding-left: 15px;
      padding-right: 15px;

      @include media-breakpoint-up(lg) {
        //padding-left: 4rem;
        //padding-right: 4rem;
      }

      section.contentPanel div.container {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }

  .multiColumnPanel section.contentPanel {
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
  }
}

a.app_version{
  color: white;
  text-decoration : none;
}

.contact__google__map {
  height: 91%;
  iframe {
    //width: auto;
    //height: 100%;
    @include media-breakpoint-up(md){
      width: auto;
      height: 100%;
    }

  }
}

.alert-danger {
  color: $font-color;
  background-color: transparent;
  border-color: black;
}

section.product__panel {
  .container{
    max-width: 1600px;
  }
}

// section{
//   .container{
//     max-width: 1600px;
//   }
// }

form.contactForm{
  .actions button{
    border-color: white;
  }
}

.search-result.productList{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: auto;
}

.search__result__page__title{
  margin-top: 0.5rem;
}

.search__result__page__bg{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 56.25%;
  transition: all 0.5s ease-out;
  background-color: #ebebeb;
}

.search__result__page__inner{
  overflow: hidden;
  a:hover{
    .search__result__page__bg{
      transform: scale(1.02);
    }
  }
}

.cart__shipping__option{
  .help-text{
    line-height: 1.1;
    color: red;
  }
}

.productView__relatedProducts{
  .productList{
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
  }
}

.shipping__option__container{
  .form-check-input{
    margin-top: 0.5rem !important;
  }
}

.grecaptcha-badge { 
  visibility: hidden;
}

.btn-primary {
  background-color: black;
  border-color: black;
  &:hover, &:focus, &:not(:disabled):not(.disabled):active{
    background-color: #2d2d2d;
    border-color: #2d2d2d;
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled):active:focus{
    box-shadow: none;
  }
}

hr {
  border-color: black;
}