.main-content {
  //padding-top: map-get($top-bar-height, mobile);
  padding-top: 96px;

  @include media-breakpoint-up(lg) {
    //padding-top: map-get($top-bar-height, desktop);
    padding-top: 88px;
  }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    //margin-right: 0.7rem;
    //border-radius: 30px;
	  background-color: black;
	  border-color: black;
    color: white;
    //font-weight: $weight-bold;
    padding: 0.7rem 3rem;
	&:hover{
      background-color: $link-hover-color;
      border-color: $link-hover-color;
    }
  }
}
